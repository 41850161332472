<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t("message.add_medicine") }}</div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form">
      <el-form ref="form" :model="form" class="aticler_m" size="small">
        <el-tabs type="border-card">
          <el-tab-pane :label="$t('message.drug')">
            <div
              class="add__likarstova"
              v-for="(term, index) in terms"
              :key="index + ' - dailyTreatments'"
            >
              <el-row :gutter="20">
                <el-col :xs="12" :md="6" :lg="6">
                  <div class="Add medicine">
                    <div
                      class="input_plusc"
                      v-for="(product, index) in term.products"
                      :key="index + ' - products'"
                    >
                      <el-form-item
                        :label="$t('message.drug_indicator_warehouse')"
                      >
                        <drugs v-model="product.product_id" size="mini"></drugs>
                      </el-form-item>
                      <i
                        @click="removeProduct(term)"
                        class="el-icon-plus el-icon-delete"
                      ></i>
                    </div>
                    <h6
                      @click="appendProduct(term)"
                      class="text-center color_p4 cursor-pointer"
                    >
                      {{ $t("message.add_drug_indicator_warehouse") }}
                    </h6>
                  </div>
                  <div class="Add medicine">
                    <div
                      class="input_plusc"
                      v-for="(
                        dailyTreatmentName, index
                      ) in term.dailyTreatmentNames"
                      :key="index + ' - dailyTreatmentNames'"
                    >
                      <el-form-item label="Назначения лекарства">
                        <el-input
                          v-model="dailyTreatmentName.name"
                          placeholder="........."
                        ></el-input>
                      </el-form-item>
                      <i
                        @click="removeDailyTraitmentName(term)"
                        class="el-icon-plus el-icon-delete"
                      ></i>
                    </div>
                    <h6
                      @click="appendDailyTraitmentName(term)"
                      class="text-center color_p4 cursor-pointer"
                    >
                      {{ $t("message.add_drug_indicator") }}
                    </h6>
                    <!-- <el-form-item>
                        <el-input v-model="form.name1" placeholder="........."></el-input>
                    </el-form-item>-->
                  </div>
                </el-col>

                <!-- end col -->
                <el-col :xs="12" :md="12" :lg="12">
                  <el-form-item
                    :label="$t('message.instruction_for_use')"
                    class="minheight"
                  >
                    <el-input type="textarea" v-model="term.detail"></el-input>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <el-col :xs="12" :md="6" :lg="6">
                  <el-form-item :label="$t('message.period')">
                    <el-date-picker
                      type="dates"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                      v-model="term.end_date"
                      :placeholder="$t('message.period')"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item :label="$t('message.purpose_quantity')">
                    <el-select v-model="term.count" placeholder=".....">
                      <el-option label="1" value="1"></el-option>
                      <el-option label="2" value="2"></el-option>
                      <el-option label="3" value="3"></el-option>
                      <el-option label="4" value="4"></el-option>
                      <el-option label="5" value="5"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- end col -->
                <!-- end col -->
              </el-row>
              <!-- end el-row -->
              <i
                @click="removeDailyTraitment(term)"
                class="add_close el-icon-circle-close"
              ></i>
            </div>
            <!-- end add__likarstova -->

            <!-- <div @click="appendDailyTraitment()" class="add_pol">Добавить лекарства</div> -->
            <div class="grid-content bg-purple-light">
              <div class="button_r_2">
                <el-button
                  type="success"
                  :loading="loadingButton"
                  :disabled="loadingButton ? true : false"
                  @click="submit(true)"
                  >{{ $t("message.save") }}</el-button
                >
                <el-button @click="close()">{{
                  $t("message.close")
                }}</el-button>
              </div>
            </div>
          </el-tab-pane>
          <!-- end  el-tab-pane-->
          <el-tab-pane label="Показатели">
            <table
              class="indicators__table table table-bordered table-hover"
              v-for="(loop, index) in loops"
              :key="index + ' - patientIndicator'"
            >
              <!-- <i @click="removePatientIndicator(loop)" class="add_close_2  el-icon-circle-close"></i> -->
              <thead>
                <tr>
                  <th scope="col">{{ $t("message.patient_indicators") }}</th>
                  <th scope="col" colspan="3">{{ $t("message.close") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <patientIndicatorNames
                      v-model="form.patient_indicator_name_id"
                      size="mini"
                    ></patientIndicatorNames>
                  </td>
                  <td>
                    <el-date-picker
                      type="dates"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                      v-model="loop.end_date"
                      :placeholder="$t('message.period')"
                    ></el-date-picker>
                  </td>
                  <td>
                    <el-select
                      size="small"
                      v-model="loop.count"
                      :placeholder="$t('message.purpose_quantity')"
                    >
                      <el-option label="1" value="1"></el-option>
                      <el-option label="2" value="2"></el-option>
                      <el-option label="3" value="3"></el-option>
                      <el-option label="4" value="4"></el-option>
                      <el-option label="5" value="5"></el-option>
                    </el-select>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <div @click="appendPatientIndicator()" class="add_pol">Добавить Показатели пациента</div> -->
            <div class="grid-content bg-purple-light">
              <div class="button_r_2">
                <el-button
                  type="success"
                  :loading="loadingButton"
                  :disabled="loadingButton ? true : false"
                  @click="submitIndicator(true)"
                  >{{ $t("message.save") }}</el-button
                >
                <el-button @click="close()">{{
                  $t("message.close")
                }}</el-button>
              </div>
            </div>
          </el-tab-pane>
          <!-- end  el-tab-pane  -->
        </el-tabs>
        <!-- end  el-tab-->
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
import drugs from "@/components/selects/drugs";
import patientIndicatorNames from "@/components/selects/patientIndicatorName";

export default {
  mixins: [form, drawerChild],
  props: ["selected"],
  data() {
    return {
      form: {
        terms: [],
        loops: [],
      },
      terms: [
        {
          dailyTreatmentNames: [{}],
          products: [{}],
        },
      ],
      loops: [
        {
          patientIndicatorNames: [],
        },
      ],
    };
  },
  components: {
    drugs,
    patientIndicatorNames,
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "dailyTreatments/rules",
      model: "dailyTreatments/model",
      columns: "dailyTreatments/columns",
      rulesIndicator: "patientIndicators/rules",
      modelIndicator: "patientIndicators/model",
      columnsIndicator: "patientIndicators/columns",
      lastHistory: "patientHistories/model",
    }),
  },
  methods: {
    ...mapActions({
      save: "dailyTreatments/update",
      empty: "dailyTreatments/empty",
      saveIndicator: "patientIndicators/update",
      emptyIndicator: "patientIndicators/empty",
    }),
    afterOpened() {
      if (this.selected && !this.loadingData) {
        this.loadingData = false;
        this.form = JSON.parse(JSON.stringify(this.modelIndicator));
        this.form.id = this.selected ? this.selected.id : null;
      }
    },
    afterClosed() {
      this.empty(), this.emptyIndicator();
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // this.form.dailyTreatmentNames = this.form.dailyTreatmentNames.map(form => { return {name: form.name}});
          // this.form.products = this.form.products.map(form => form.id);
          this.form.terms = this.terms;
          this.loadingButton = true;
          this.form.patient_history_id = this.lastHistory.id;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.parent().listChanged();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    submitIndicator(closeIndicator = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // this.form.dailyTreatmentNames = this.form.dailyTreatmentNames.map(form => { return {name: form.name}});
          // this.form.products = this.form.products.map(form => form.id);
          this.form.loops = this.loops;
          this.loadingButton = true;
          this.form.patient_history_id = this.lastHistory.id;
          this.saveIndicator(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.parent().listChanged();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    appendDailyTraitmentName(term) {
      term.dailyTreatmentNames.push({
        name: "",
      });
    },
    appendDailyTraitment() {
      this.terms.push({
        dailyTreatmentNames: [{}],
        products: [{}],
      });
    },
    appendPatientIndicator() {
      this.loops.push({
        patientIndicatorNames: [{}],
      });
    },
    appendProduct(term) {
      term.products.push({});
    },
    removeProduct(term) {
      term.products.splice(term, 1);
    },
    removeDailyTraitmentName(term) {
      term.dailyTreatmentNames.splice(term, 1);
    },
    removeDailyTraitment(index) {
      this.terms.splice(index, 1);
    },
    removePatientIndicator(index) {
      this.loops.splice(index, 1);
    },
  },
};
</script>
<style lang="scss">
.input_plusc {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .el-icon-plus {
    border-radius: 5px;
    border: 1px solid #dcdfe6;
    height: 32px;
    line-height: 30px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: #dcdfe6;
    cursor: pointer;
  }
}

.el-form-item {
  flex: 1;
}

.minheight .el-textarea__inner {
  min-height: 100px !important;
}

.add__likarstova {
  padding: 15px 15px 0px 15px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
}

.add_pol {
  text-align: center;
  color: #3a90c3;
  font-size: 15px;
  cursor: pointer;
}

.indicators__table .el-checkbox__label {
  font-size: 14px !important;
  color: #303030 !important;
}

.indicators__table th {
  font-size: 16px !important;
  color: #303030 !important;
}

.add_close {
  position: absolute;
  top: -14px;
  right: -11px;
  background: #fff;
  color: red;
  font-size: 24px;
  cursor: pointer;
}

.add_close_2 {
  position: absolute;
  top: 3px;
  right: 4px;
  background: #fff;
  color: red;
  font-size: 24px;
  cursor: pointer;
}

.color_p4 {
  margin-top: -10px;
  margin-bottom: 12px;
  font-size: 15px;
}

.button_r_2 {
  text-align: right;
  margin-top: 0px !important;
}
</style>